export const textEng=[
    "Why is this important?",
    "Click",
    " here ",
    "to visit the Why - page.",
    "What are the goals of this initiative? ",
    "To put forward a motion in the Swedish Riksdag that the government implements the ten action points.",
    "Why is this initiative focused on the Members of Parliament?",
    "All people in society have a joint responsibility for the future of our children. This is true for our politically elected representatives, it’s true for public and private organizations, and it’s true for all citizens, every single one of us. At the same time, it’s the Swedish Parliament that holds the greatest power. While all public power in Sweden proceeds from the people, the Swedish Parliament is the foremost representative of the people. They decide on laws that then apply to the national courts and to which the government must work to comply. This is why it’s our elected Members of Parliament who hold the greatest responsibility.",
    "Why is it important that we are many who send emails to the Members of Parliament?",
    "By asking each and every one of the Members of Parliament the same question, again and again, until all of them have answered, we influence policy and the public debate. It’s important that the question is raised by many, common citizens, to show that we’re many who believe this is crucial. The more people who contact the Members of Parliament, the greater the chance that we succeed. Together we can make a difference! ",
    "Why are we all sending the same email? ",
    'Use the headline "Riksdagen för framtiden", and add your own name. (Riksdagen för framtiden - Jane Doe). This is to avoid potential email spam filters. Copy and paste the text, without making any changes, and send it from your own personal email address. The idea behind this initiative is that we, common citizens, ask the Members of Parliament the same question to show them how many of us are concerned! This is why it’s important that all Members of Parliament receive the same email. You’re of course free to write to our Members of Parliament about anything you like, but if you choose to do it as part of this initiative, it’s important that all Members of Parliament receive the same email.',
    "To how many Members of Parliament should I send an email? ",
    "To as many as you like. You don’t have to wait until one has answered before you move on to contact the next.",
    "What happens when I’ve sent the email to the Member of Parliament? How do I know when to stop?",
    "In the text sent to the Members of Parliament they are encouraged to send their response to kontakt@riksdagenforframtiden.se. As soon as a Member of Parliament has submitted a response it will be published on this website. Then it’s just for you to move on, sending the letter to the next Member of Parliament, until they’ve all answered.",
    "Will I receive any feedback after sending my email?",
    "You will personally not receive any feedback, other than if the Member of Parliament should also reply back to you.",
    "Will my name be visible in any way?",
    "Your name, or email address won’t be visible on the website nor saved in any other way. It will only reach the Member of Parliament whom you’ve chosen to contact.",
]