import React from "react";
import {Box, Text, Anchor} from "grommet"
import { TextPageLayout } from "../textPageLayout";
import { textSwe } from "./textSwe";
import { textEng } from "./textEng";
import {useSelector} from "react-redux"


export const FaqPageComponent = () => {
    const language = useSelector(state=> state.language.language);
    const textArray = language==="SWE"?textSwe:textEng;
    const headingText = language==="SWE"?"Frågor och svar":"FAQ";
    const content = 
    <Box gap="medium">
                    <Box gap="small">
                        <Text weight="bold" color="darkGreen">{textArray[0]}</Text>
                        <Text color="darkGreen">{textArray[1]}<Anchor href="/about"> {textArray[2]} </Anchor>{textArray[3]}</Text>
                    </Box>
                    <Box gap="small">
                        <Text weight="bold" color="darkGreen">{textArray[4]}</Text>
                        <Text color="darkGreen">{textArray[5]}</Text>
                    </Box>
                    <Box gap="small">
                        <Text weight="bold" color="darkGreen">{textArray[6]}</Text>
                        <Text color="darkGreen">{textArray[7]}</Text>
                    </Box>
                    <Box gap="small">
                        <Text weight="bold" color="darkGreen">{textArray[8]}</Text>
                        <Text color="darkGreen">{textArray[9]}</Text>
                    </Box>
                    <Box gap="small">
                        <Text weight="bold" color="darkGreen">
                        {textArray[10]}
                        </Text>
                        <Text color="darkGreen">
                        {textArray[11]}
                        </Text>
                    </Box>
                    <Box gap="small">
                        <Text weight="bold" color="darkGreen">
                        {textArray[12]}
                        </Text>
                        <Text color="darkGreen">
                        {textArray[13]}
                        </Text>
                    </Box>
                    <Box gap="small">
                        <Text weight="bold" color="darkGreen">
                        {textArray[14]}
                        </Text>
                        <Text  color="darkGreen" wordBreak="break-word">
                        {textArray[15]}
                        </Text>
                    </Box>
                    <Box gap="small">
                        <Text weight="bold" color="darkGreen">
                        {textArray[16]}
                        </Text>
                        <Text color="darkGreen">
                        {textArray[17]}
                        </Text>
                    </Box>
                    <Box gap="small">
                        <Text weight="bold" color="darkGreen">
                        {textArray[18]}
                        </Text>
                        <Text color="darkGreen">
                        {textArray[19]}
                        </Text>
                    </Box>
                    <Box gap="small">
                        <Text weight="bold" color="darkGreen">
                        {textArray[20]}
                        </Text>
                        <Text color="darkGreen">
                        {textArray[21]}
                        </Text>
                    </Box>
                </Box>
    return (

    <TextPageLayout header={headingText} content={content}/>
    )
}
