export const textSwe=[
    "Varför är det här viktigt?",
    "Klicka",
    " här ",
    "för att komma till Varför sidan.",
    "Vad är målet med initiativet?",
    "Att få fram en motion till riksdagen för att genomföra de tio punkterna.",
    "Varför vänder vi oss just till riksdagsledamöterna?",
    "Alla i samhället har ett gemensamt ansvar för våra barns framtid. Det gäller våra politiskt folkvalda, det gäller offentliga och privata organisationer, och det gäller alla oss medborgare, var och en av oss. Samtidigt är det så att den institution i samhället som har mest makt är riksdagen. De stiftar lagar som sedan domstolar tillämpar och regeringen måste arbeta för att följa. Därför är det också våra riksdagsledamöter som har det största ansvaret.",
    "Varför är det viktigt att vi är många som mejlar riksdagsledamöterna?",
    "Genom att ställa frågan till var och en dem, gång på gång, tills alla har svarat, påverkar vi debatten. Det är en viktig poäng att frågan ställs av många, av oss vanliga medborgare, för att visa att vi är många i det här landet som tycker att det här är så angeläget. Ju fler vi är som hjälps åt, desto större chans att vi lyckas. Tillsammans gör vi skillnad!",
    "Varför skickar vi alla samma mejl?",
    'Använd rubrikraden "Riksdagen för framtiden", och lägg till ditt eget namn. (Riksdagen för framtiden + Test Testsson). Detta för att komma runt eventuella spam-filter. Kopiera och klistra in texten, utan att ändra den. Idén med det här initiativet är att vi, vanliga medborgare, ställer ledamöterna samma fråga och visar att vi är många! Därför är det viktigt att alla ledamöter får samma meddelande. Du kan självklart skriva vad du vill till våra riksdagsledamöter, men om du väljer att göra det som en del av det här initiativet, så är det viktigt att alla ledamöter får samma meddelande.',
    "Hur många riksdagsledamöter ska jag mejla?",
    "Så många du vill. Du behöver inte vänta tills en har svarat innan du väljer nästa.",
    "Vad händer när jag mejlat riksdagsledamoten? Hur vet jag när jag ska sluta?",
    "I mejlet till riksdagsledamöterna uppmanas de att skicka sitt svar till kontakt@riksdagenforframtiden.se. När en riksdagsledamot svarar på frågan kommer det att synas på den här webbplatsen. Då är det bara att släppa den, och istället fortsätter vi med att ställa frågan till nästa ledamot tills alla svarat.",
    "Får jag någon återkoppling efter att ha skickat mitt mejl?",
    "Du får själv ingen återkoppling, annat än om riksdagsledamoten skulle svara även tillbaka till dig.",
    "Kommer mitt namn vara synligt på något sätt?",
    "Ditt namn, eller din e-postadress, kommer inte att synas på webb eller sparas på annat sätt, utan enbart nå de riksdagsledamöter som du har valt att kontakta.",
]